<template>
  <div>
    <teleport to="body">
      <div class="menu" style="background-color: white; width: 7em;">
        <b @click='menu.open = !menu.open'>menu</b>  {{ menu.open ? '▼' : '▲' }}
        <table v-if='menu.open' style="border-collapse: collapse;">
          <tr>
            <td @click='onReset' style="border: 1px dotted gray; padding: 1em;">
              Reset all
            </td>
          </tr>
          <tr>
            <td style="border: 1px dotted gray; padding: 1em;">
              <div @click="menu.editData = true">
                Data
              </div>
              <teleport to="body">
                <div v-if="menu.editData" class="modal">
                  <div>
                    <textarea
                        style="height: 85vh; width: 90vw;"
                        @focus="$event.target.select()"
                        :value="JSON.stringify(data, null, 2)"
                        @input="menu.tmpData = $event.target.value">
                    </textarea>
                    <div class="button" @click="data = JSON.parse(menu.tmpData); menu.editData = false; menu.open = false;" style="color: red; border-color: red; width: 35vw;">
                      Save
                    </div>
                    <div class="button" @click="menu.editData = false; menu.open = false;" style="color: green; border-color: green; width: 35vw;">
                      Close
                    </div>
                  </div>
                </div>
              </teleport>
            </td>
          </tr>
          <tr>
            <td style="border: 1px dotted gray; padding: 1em;">
              <div @click="menu.editDataStructure = true">
                Structure
              </div>
              <teleport to="body">
                <div v-if="menu.editDataStructure" class="modal">
                  <div>
                    <textarea
                        style="height: 85vh; width: 90vw;"
                        @focus="$event.target.select()"
                        :value="JSON.stringify(cleanedData, null, 2)"
                        @input="menu.tmpDataStructure = $event.target.value">
                    </textarea>
                    <div class="button" @click="data = prepareData(JSON.parse(menu.tmpDataStructure)); menu.editDataStructure = false; menu.open = false;" style="color: red; border-color: red; width: 35vw;">
                      Save
                    </div>
                    <div class="button" @click="menu.editDataStructure = false; menu.open = false;" style="color: green; border-color: green; width: 35vw;">
                      Close
                    </div>
                  </div>
                </div>
              </teleport>
            </td>
          </tr>
        </table>
      </div>
    </teleport>
    <div style="height: 15vh; margin: 0; display: inline-block;">
      <br>
      <b>Warehouse rows filter:</b>
      <table class="storage_rows">
        <tr>
          <td v-for="storageRow of storageRows"
              :key="storageRow.name"
              @click="toggleStorageRow(storageRow.name)"
              :class="{ storage_row_hidden: !isStorageRowVisible(storageRow.name), storage_row_has_missing: storageRow.hasMissing }">
            {{ storageRow.name }}
          </td>
        </tr>
      </table>
      <div class="button"
           style="border-color: green; color: green;"
           @click="data.showMissingOnly = !data.showMissingOnly"
           :class="{ show_missing_active: data.showMissingOnly }">
        Show missing only
      </div>
    </div>
    <ul style="height: 85vh; overflow: auto; margin: 0;">
      <li v-for="department of filterDepartments(data.departments)" :key="department.name">
        <b @click='department.open = !department.open'>Department: {{ department.name }}</b> {{ department.open ? '▼' : '▲' }}
        <ul v-if='department.open'>
          <li v-for="shopPosition of filterShopPositions(department.shopPositions)" :key="shopPosition.name">
            <b @click='shopPosition.open = !shopPosition.open'
                :class="{ item_has_missing: shopPosition.types.some(type => type.kinds.some(isKindMissing)) }">
              {{ shopPosition.name }}
            </b> {{ shopPosition.open ? '▼' : '▲' }}
            <div v-if='shopPosition.open' style="float:right; color: gray;" @click='shopPosition.edit = true'>
              Edit
            </div>
            <teleport to="body">
              <div v-if="shopPosition.edit" class="modal">
                <div style="display: inline-block;">
                  <input type="text" v-model="shopPosition.name" style="margin-bottom: 10px;">
                  <div style="display: inline-block;">
                    types:
                    <div v-for="(type, typeIndex) of shopPosition.types" :key="typeIndex" style="padding: 1em; border: 1px dotted gray; display: inline-block;">
                      <div style="display: inline-block;">
                        <label style="display: block; float: right; margin: 2px;">Type: <input type="text" v-model="type.name"></label>
                        <label style="display: block; float: right; margin: 2px;">Warehouse row: <input type="text" v-model="type.storageRow"></label>
                        <label style="display: block; float: right; margin: 2px;">Position: <input type="text" v-model="type.storagePosition"></label>
                      </div>
                      <div v-for="(kind, kindIndex) of type.kinds" :key="kindIndex" style="padding: 1em; border: 1px dotted gray;">
                        <input type="text" v-model="kind.name" style="width: 50vw;">
                        <div style="float: right; width: 4em; padding: 0; margin: 0;">
                          <span style="color: blue; float: left;" v-if="kindIndex > 0" @click="type.kinds[kindIndex] = type.kinds[kindIndex - 1]; type.kinds[kindIndex - 1] = kind">
                            ▲
                          </span>
                          <span style="color: blue; float: right;" v-if="kindIndex + 1 < type.kinds.length" @click="type.kinds[kindIndex] = type.kinds[kindIndex + 1]; type.kinds[kindIndex + 1] = kind">
                            ▼
                          </span>
                        </div>
                      </div>
                      <div style="float: right; color: blue;" @click="type.kinds.push(prepareKind({ name: '' }))">
                        Add kind
                      </div>
                    </div>
                    <div style="float: right; color: blue; display: inline-block;" @click="shopPosition.types.push(prepareType({ name: '', storageRow: 0, storagePosition: '', kinds: [] }))">
                      Add type
                    </div>
                  </div>
                  <div style="float: left; border-color: green; color: green; width: 50vw; float: right; margin-bottom: 1em;" class="button" @click="shopPosition.edit = false">OK</div>
                </div>
              </div>
            </teleport>
            <ul v-if='shopPosition.open'>
              <li v-for="type of filterTypes(shopPosition.types)" :key="type.name">
                <b>{{ type.name }}</b><br>
                Warehouse row: {{ type.storageRow }}<br>
                Position: {{ type.storagePosition }}
                <ul>
                  <li v-for="kind of filterKinds(type.kinds)" :key="kind.name">
                    <div style="border: 1px dotted gray; margin: 5px; padding: 5px;"
                         @click.self="kind.showWindow = true"
                         :class="{ item_has_missing: isKindMissing(kind), kind_no: kind.status !== 0 }">
                      {{ kind.name }} {{ kind.missing.reduce((sum, cur) => sum + cur) + kind.additional }}
                      <input type="checkbox" v-if="isKindMissing(kind)" @click="clearKind(kind)" style="float: right;">
                    </div>
                    <teleport to="body">
                      <div v-if="kind.showWindow" class="modal">
                        <div>
                          <h3>{{ type.name }} {{ kind.name }}</h3>
                          <label style="display: block; float: right; margin-bottom: 1em;">Additional: <input type="number" max="99" v-model.number="kind.additional" @focus="$event.target.select()"></label>
                          <table class="select_missing" @touchmove="selectCell">
                            <tr v-for="row of rowNumbers" :key="row">
                              <td v-for="(missing, cell) of kind.missing" :key="cell" :class="{ cell_missing: row <= missing }" @click="kind.missing[cell] = Math.max(missing, row)">
                                {{ row > missing ? '+' : '-' }}
                              </td>
                            </tr>
                            <tr>
                              <td v-for="(missing, cell) of kind.missing" :key="cell" style="background-color: gray" @click="kind.missing[cell] = 0">
                                -
                              </td>
                            </tr>
                          </table>
                          <div v-if="kind.status !== 0" class="button" style="border-color: green; color: green; width: 35%;" @click="kind.status = 0; kind.showWindow = false">
                            Found
                          </div>
                          <div v-else style="padding: 0; margin: 0;">
                            <div class="button" style="border-color: gray; color: gray; width: 20%;" @click="kind.status = 1; kind.showWindow = false">
                              Not found
                            </div>
                            <div class="button" style="border-color: gray; color: gray; width: 20%;" @click="kind.status = 2; kind.showWindow = false">
                              Empty
                            </div>
                          </div>
                          <div class="button" style="border-color: green; color: green; width: 35%; float: right;" @click="kind.showWindow = false">
                            OK
                          </div>
                        </div>
                      </div>
                    </teleport>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div style="float: right; color: blue;" @click="data.departments.push(prepareKind({ name: '' }))">
      Add department
    </div>
  </div>
</template>

<script setup>
  // imported components are also directly usable in template
//  import Foo from './Foo.vue'
  import { ref, watch, computed } from 'vue'
  
//  window.onbeforeunload = () => '' // prevent reload
  const menu = ref({
    open: false,
    editData: false,
    editDataStructure: false,
  })
  
  const prepareKind = kind => Object.assign(kind, {
    showWindow: false,
    found: true,
    missing: Array(6).fill(0),
    additional: 0,
    status: 0,
  })
  const prepareType = type => {
    type.kinds.forEach(prepareKind)
    return type
  }
  const prepareShopPosition = shopPosition => {
    shopPosition.types.forEach(prepareType)
    return Object.assign(shopPosition, {
      open: false,
      edit: false,
    })
  }
  const prepareDepartment = department => {
    department.shopPositions.forEach(prepareShopPosition)
    return Object.assign(department, {
      open: false,
    })
  }
  const prepareData = data => {
    data.departments.forEach(prepareDepartment)
    return Object.assign(data, {
      filterStorageRows: {},
      showMissingOnly: false,
    })
  }
  const cleanKind = kind => ({ name: kind.name })
  const cleanType = type => {
    const result = Object.assign({}, type)
    result.storageRow = result.storageRow.toString()
    result.kinds = result.kinds.map(cleanKind)
    return result
  }
  const cleanShopPosition = shopPosition => {
    const result = Object.assign({}, shopPosition)
    delete result.open
    delete result.edit
    result.types = result.types.map(cleanType)
    return result
  }
  const cleanDepartment = department => {
    const result = Object.assign({}, department)
    delete result.open
    result.shopPositions = result.shopPositions.map(cleanShopPosition)
    return result
  }
  const cleanData = data => {
    const result = Object.assign({}, data)
    result.departments = result.departments.map(cleanDepartment)
    delete result.filterStorageRows
    delete result.showMissingOnly
    return result
  }
  const cleanedData = computed(() => cleanData(data.value))
  const importData = data => {
    prepareData(data)
    localStorage.setItem('data', JSON.stringify(data))
  }
  const initData = () => {
    const data = {
      "departments": [
        {
          "name": "Мыло и гели для душа",
          "shopPositions": [
            {
              "name": "стеллаж 1",
              "types": [
                {
                  "name": "Dove большой",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 3 внизу",
                  "kinds": [
                    {
                      "name": "огурец"
                    },
                    {
                      "name": "алоэ"
                    },
                    {
                      "name": "оливки"
                    },
                    {
                      "name": "гранат"
                    },
                    {
                      "name": "кокос"
                    },
                    {
                      "name": "розовый пион"
                    },
                    {
                      "name": "коричневый"
                    },
                    {
                      "name": "синий"
                    }
                  ]
                },
                {
                  "name": "Dove маленький",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 3",
                  "kinds": [
                    {
                      "name": "розовый"
                    },
                    {
                      "name": "зелёный"
                    },
                    {
                      "name": "голубой мицеллярная вода"
                    },
                    {
                      "name": "синий"
                    },
                    {
                      "name": "огурец"
                    },
                    {
                      "name": "авокадо"
                    },
                    {
                      "name": "пшеница"
                    },
                    {
                      "name": "summer"
                    },
                    {
                      "name": "какао"
                    },
                    {
                      "name": "розовый цветок"
                    }
                  ]
                },
                {
                  "name": "P24 канистра",
                  "storageRow": "10",
                  "storagePosition": "возле лифта",
                  "kinds": [
                    {
                      "name": "Сиреневый с красным цветком"
                    },
                    {
                      "name": "Жёлтый с красным цветком"
                    },
                    {
                      "name": "Сиреневый с жёлтым цветком"
                    },
                    {
                      "name": "Синий с жёлтым цветком"
                    },
                    {
                      "name": "Роза сиреневый"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 2",
              "types": [
                {
                  "name": "Axe",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 2",
                  "kinds": [
                    {
                      "name": "black"
                    },
                    {
                      "name": "wild"
                    },
                    {
                      "name": "collision"
                    },
                    {
                      "name": "ice chill"
                    }
                  ]
                },
                {
                  "name": "Crema бутылка",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 6-8",
                  "kinds": [
                    {
                      "name": "SPA"
                    },
                    {
                      "name": "голубой"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "роза"
                    },
                    {
                      "name": "роза"
                    },
                    {
                      "name": "розовый"
                    },
                    {
                      "name": "красный"
                    },
                    {
                      "name": "звезда"
                    },
                    {
                      "name": "апельсин"
                    },
                    {
                      "name": "имбирь"
                    },
                    {
                      "name": "белый цветок с жёлтой надписью"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 3",
              "types": [
                {
                  "name": "Palmolive ЛИТР",
                  "storageRow": "5",
                  "storagePosition": "В начале ряда между стеллажами",
                  "kinds": [
                    {
                      "name": "молоко"
                    },
                    {
                      "name": "мёд"
                    }
                  ]
                },
                {
                  "name": "Palmolive большой с носиком",
                  "storageRow": "5",
                  "storagePosition": "Стеллаж 9",
                  "kinds": [
                    {
                      "name": "гранат"
                    },
                    {
                      "name": "розовый мутный"
                    },
                    {
                      "name": "голубой massage алоэ"
                    },
                    {
                      "name": "мёд"
                    },
                    {
                      "name": "персик"
                    },
                    {
                      "name": "оливки"
                    },
                    {
                      "name": "миндаль"
                    },
                    {
                      "name": "сиреневый цветок на белом фоне"
                    },
                    {
                      "name": "апельсин"
                    },
                    {
                      "name": "ежевика"
                    },
                    {
                      "name": "авокадо Oils"
                    },
                    {
                      "name": "орех Oils"
                    },
                    {
                      "name": "мицеллярная синий цветок"
                    },
                    {
                      "name": "мицеллярная хлопок"
                    },
                    {
                      "name": "мицеллярная роза"
                    },
                    {
                      "name": "сиреневый сплошной цвет"
                    },
                    {
                      "name": "men синий"
                    },
                    {
                      "name": "men чёрный"
                    },
                    {
                      "name": "clay detox алоэ"
                    },
                    {
                      "name": "clay rejuvenation роза"
                    },
                    {
                      "name": "clay tonus листики"
                    }
                  ]
                },
                {
                  "name": "Palmolive средний бутылка",
                  "storageRow": "5",
                  "storagePosition": "Стеллаж 9",
                  "kinds": [
                    {
                      "name": "миндаль"
                    },
                    {
                      "name": "мёд"
                    },
                    {
                      "name": "сиреневый сплошной цвет"
                    },
                    {
                      "name": "coconut oil"
                    },
                    {
                      "name": "ежевика"
                    },
                    {
                      "name": "men зелёный лайм"
                    },
                    {
                      "name": "голубой мутный massage"
                    },
                    {
                      "name": "розовый с белой розой"
                    },
                    {
                      "name": "зелёный с белым цветком"
                    },
                    {
                      "name": "инжир oil"
                    },
                    {
                      "name": "молоко"
                    }
                  ]
                },
                {
                  "name": "Кейв Канистра",
                  "storageRow": "3",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "имбирь"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "звезда"
                    },
                    {
                      "name": "ромашка"
                    },
                    {
                      "name": "алоэ"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 4",
              "types": [
                {
                  "name": "Кейв бутылка",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 4-5",
                  "kinds": [
                    {
                      "name": "party"
                    },
                    {
                      "name": "сноуборд"
                    },
                    {
                      "name": "гамак"
                    },
                    {
                      "name": "сиреневый с белым цветком"
                    },
                    {
                      "name": "кокос"
                    },
                    {
                      "name": "мёд"
                    },
                    {
                      "name": "звезда"
                    },
                    {
                      "name": "миндаль"
                    },
                    {
                      "name": "роза"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "мороженое jelly"
                    },
                    {
                      "name": "мороженое lemon"
                    },
                    {
                      "name": "торт мороженое"
                    },
                    {
                      "name": "блины с бананом"
                    },
                    {
                      "name": "торт фисташки"
                    },
                    {
                      "name": "мороженое fluffy"
                    },
                    {
                      "name": "мороженое salty"
                    },
                    {
                      "name": "мороженое orange"
                    },
                    {
                      "name": "мороженое cookies"
                    },
                    {
                      "name": "мороженое frozen"
                    },
                    {
                      "name": "мороженое mango"
                    },
                    {
                      "name": "мороженое berries"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 5",
              "types": [
                {
                  "name": "Docror OR бутылка",
                  "storageRow": "8",
                  "storagePosition": "Сторона 2 стеллаж 5",
                  "kinds": [
                    {
                      "name": "сиреневый цветок"
                    },
                    {
                      "name": "орехи круглые"
                    },
                    {
                      "name": "красный цветок и миндаль"
                    },
                    {
                      "name": "кокос"
                    },
                    {
                      "name": "конопля огурец"
                    },
                    {
                      "name": "белый цветок"
                    },
                    {
                      "name": "жёлтые капли"
                    },
                    {
                      "name": "зелёный цветок"
                    }
                  ]
                },
                {
                  "name": "PERFECT care прямоугольная бутылка",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 3",
                  "kinds": [
                    {
                      "name": "musk сиреневый"
                    },
                    {
                      "name": "honey жёлтый"
                    },
                    {
                      "name": "coconut коричневый"
                    },
                    {
                      "name": "olive зелёный"
                    },
                    {
                      "name": "камушки голубой"
                    }
                  ]
                },
                {
                  "name": "Hawaii бутылка",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 4",
                  "kinds": [
                    {
                      "name": "синий миндаль"
                    },
                    {
                      "name": "розовый кокос"
                    },
                    {
                      "name": "сиреневый цветок"
                    },
                    {
                      "name": "красный шар"
                    },
                    {
                      "name": "золотистый цветок"
                    },
                    {
                      "name": "бежевый пион"
                    },
                    {
                      "name": "жёлтый шар"
                    },
                    {
                      "name": "малиновый с оранжевыми цветами"
                    },
                    {
                      "name": "жёлтый мёд"
                    },
                    {
                      "name": "красный арбуз"
                    },
                    {
                      "name": "черника"
                    },
                    {
                      "name": "розовый цветок"
                    }
                  ]
                },
                {
                  "name": "Marsellais",
                  "storageRow": "8",
                  "storagePosition": "Сторона 2 стеллаж 5 внизу",
                  "kinds": [
                    {
                      "name": "красный"
                    },
                    {
                      "name": "жёлтый"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 6",
              "types": [
                {
                  "name": "7 литр",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 4-6",
                  "kinds": [
                    {
                      "name": "миндаль"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "ромашка"
                    },
                    {
                      "name": "алоэ"
                    },
                    {
                      "name": "роза"
                    }
                  ]
                },
                {
                  "name": "7 750",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 4-6",
                  "kinds": [
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "ваниль"
                    },
                    {
                      "name": "алоэ"
                    },
                    {
                      "name": "роза"
                    },
                    {
                      "name": "огурец лайм"
                    },
                    {
                      "name": "ромашка"
                    },
                    {
                      "name": "кокос"
                    },
                    {
                      "name": "миндаль"
                    },
                    {
                      "name": "жёлтые капли"
                    },
                    {
                      "name": "узор красный"
                    },
                    {
                      "name": "узор розовый"
                    },
                    {
                      "name": "узор коричневый"
                    },
                    {
                      "name": "узор синий"
                    }
                  ]
                },
                {
                  "name": "Nivea men",
                  "storageRow": "7",
                  "storagePosition": "Сторона 2 стеллаж 1",
                  "kinds": [
                    {
                      "name": "deep clean"
                    },
                    {
                      "name": "cool kick"
                    },
                    {
                      "name": "sport"
                    },
                    {
                      "name": "energy"
                    },
                    {
                      "name": "power fresh"
                    }
                  ]
                },
                {
                  "name": "Nivea бутылка",
                  "storageRow": "7",
                  "storagePosition": "Сторона 2 стеллаж 2",
                  "kinds": [
                    {
                      "name": "creme smooth"
                    },
                    {
                      "name": "creme aloe"
                    },
                    {
                      "name": "coconut"
                    },
                    {
                      "name": "relax"
                    },
                    {
                      "name": "rose"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 7",
              "types": [
                {
                  "name": "Family мыло с уголками",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 6",
                  "kinds": [
                    {
                      "name": "розовый"
                    },
                    {
                      "name": "голубой"
                    },
                    {
                      "name": "белый"
                    }
                  ]
                },
                {
                  "name": "Пнина Розенблюм мыло 3",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 2",
                  "kinds": [
                    {
                      "name": "разноцветное"
                    }
                  ]
                },
                {
                  "name": "Пнина Розенблюм мыло маленькое",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 2",
                  "kinds": [
                    {
                      "name": "голубой"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "жёлтый"
                    }
                  ]
                },
                {
                  "name": "Dr.Fisher прямоугольный литр с носиком",
                  "storageRow": "9",
                  "storagePosition": "Сторона 2 стеллаж 1 внизу",
                  "kinds": [
                    {
                      "name": "сиреневый миндаль"
                    },
                    {
                      "name": "зелёный алоэ"
                    },
                    {
                      "name": "розовый оливки"
                    },
                    {
                      "name": "синий сирень"
                    },
                    {
                      "name": "белый оливки"
                    },
                    {
                      "name": "жёлтый орех"
                    }
                  ]
                },
                {
                  "name": "Пинук литр с носиком",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 5-7",
                  "kinds": [
                    {
                      "name": "кокос"
                    },
                    {
                      "name": "облепиха"
                    },
                    {
                      "name": "синий с цветком"
                    },
                    {
                      "name": "сиреневый"
                    }
                  ]
                },
                {
                  "name": "Style литр с носиком",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 0 торец",
                  "kinds": [
                    {
                      "name": "green tea"
                    },
                    {
                      "name": "white tea"
                    }
                  ]
                },
                {
                  "name": "Style маленький с носиком",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 0 торец",
                  "kinds": [
                    {
                      "name": "special edition"
                    },
                    {
                      "name": "salvia tea"
                    },
                    {
                      "name": "green tea"
                    },
                    {
                      "name": "red tea"
                    },
                    {
                      "name": "black tea"
                    }
                  ]
                },
                {
                  "name": "Dove 0.5 с носиком",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 2 внизу",
                  "kinds": [
                    {
                      "name": "каштан"
                    },
                    {
                      "name": "молоко"
                    },
                    {
                      "name": "огурец"
                    }
                  ]
                },
                {
                  "name": "Пинук бутылка прямоугольная",
                  "storageRow": "7",
                  "storagePosition": "Стеллаж 5-7",
                  "kinds": [
                    {
                      "name": "синий с цветком"
                    },
                    {
                      "name": "оливки"
                    },
                    {
                      "name": "облепиха"
                    },
                    {
                      "name": "кокос"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "клубника и ягоды"
                    },
                    {
                      "name": "персик манго"
                    },
                    {
                      "name": "ананас дыня"
                    },
                    {
                      "name": "апельсин кокос"
                    }
                  ]
                },
                {
                  "name": "Пнина Розенблюм бутылка",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 2",
                  "kinds": [
                    {
                      "name": "белый"
                    },
                    {
                      "name": "розовый"
                    },
                    {
                      "name": "зелёный"
                    },
                    {
                      "name": "голубой"
                    }
                  ]
                },
                {
                  "name": "Hawaii мыло 3",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 2",
                  "kinds": [
                    {
                      "name": "синий"
                    },
                    {
                      "name": "зелёный"
                    },
                    {
                      "name": "красный"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 8",
              "types": [
                {
                  "name": "Crema мыло 0.3 носик",
                  "storageRow": "2",
                  "storagePosition": "Сторона 2 стеллаж 2",
                  "kinds": [
                    {
                      "name": "роза"
                    },
                    {
                      "name": "орех"
                    }
                  ]
                },
                {
                  "name": "Family мыло 3",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 6",
                  "kinds": [
                    {
                      "name": "розовый голубой"
                    },
                    {
                      "name": "жёлтый бежевый"
                    },
                    {
                      "name": "сиреневый"
                    }
                  ]
                },
                {
                  "name": "Palmolive мыло 3",
                  "storageRow": "5",
                  "storagePosition": "Стеллаж 7",
                  "kinds": [
                    {
                      "name": "жёлтый"
                    },
                    {
                      "name": "красный"
                    }
                  ]
                },
                {
                  "name": "Perfect мыло 3",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 3",
                  "kinds": [
                    {
                      "name": "жёлтый"
                    },
                    {
                      "name": "розовый"
                    },
                    {
                      "name": "синий"
                    }
                  ]
                },
                {
                  "name": "Кейв жёлтое мыло 3",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 4 внизу",
                  "kinds": [
                    {
                      "name": "жёлтый"
                    }
                  ]
                },
                {
                  "name": "Кейв разноцветное мыло 3",
                  "storageRow": "2",
                  "storagePosition": "Стеллаж 0 сторона 2 возле лифта",
                  "kinds": [
                    {
                      "name": "разноцветный"
                    }
                  ]
                },
                {
                  "name": "Family мыло литр",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 6",
                  "kinds": [
                    {
                      "name": "бежевый"
                    },
                    {
                      "name": "сиреневый"
                    },
                    {
                      "name": "жёлтый"
                    },
                    {
                      "name": "белый"
                    },
                    {
                      "name": "оранжевый"
                    },
                    {
                      "name": "розовый"
                    }
                  ]
                }
              ]
            },
            {
              "name": "стеллаж 9",
              "types": [
                {
                  "name": "7 мыло 3",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 6 внизу",
                  "kinds": [
                    {
                      "name": "уголки чёрные"
                    },
                    {
                      "name": "обои"
                    },
                    {
                      "name": "сердечки золотые"
                    },
                    {
                      "name": "цветочки"
                    },
                    {
                      "name": "листики золотые"
                    }
                  ]
                },
                {
                  "name": "Кейв литр с носиком",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 4-5",
                  "kinds": [
                    {
                      "name": "арбуз"
                    },
                    {
                      "name": "персик"
                    },
                    {
                      "name": "инжир"
                    },
                    {
                      "name": "алоэ"
                    },
                    {
                      "name": "имбирь"
                    },
                    {
                      "name": "звезда"
                    },
                    {
                      "name": "ромашка"
                    },
                    {
                      "name": "сиреневый"
                    }
                  ]
                },
                {
                  "name": "Perfect мыло литр",
                  "storageRow": "6",
                  "storagePosition": "Сторона 2 стеллаж 3",
                  "kinds": [
                    {
                      "name": "розовый"
                    },
                    {
                      "name": "синий"
                    },
                    {
                      "name": "жёлтый"
                    }
                  ]
                },
                {
                  "name": "Hawaii высокая кривая синяя бутылка",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 4",
                  "kinds": [
                    {
                      "name": "синий"
                    }
                  ]
                },
                {
                  "name": "Дермопон/Бат орен высокая кривая бутылка",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 1 сторона 2 внизу",
                  "kinds": [
                    {
                      "name": "зелёная крышка"
                    },
                    {
                      "name": "белая крышка"
                    },
                    {
                      "name": "синяя крышка"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "Гигиена и первая помощь",
          "shopPositions": [
            {
              "name": "Стеллаж 1",
              "types": [
                {
                  "name": "Alcogel Vish 70%",
                  "storageRow": "4",
                  "storagePosition": "Торец за перчатками",
                  "kinds": [
                    {
                      "name": "0.5"
                    }
                  ]
                },
                {
                  "name": "Alcogel Sano 70%",
                  "storageRow": "",
                  "storagePosition": "",
                  "kinds": [
                    {
                      "name": "Зелёный 0.3"
                    }
                  ]
                },
                {
                  "name": "Антисептики Dr.Fisher",
                  "storageRow": "9",
                  "storagePosition": "Сторона 2 стеллаж 1 внизу",
                  "kinds": [
                    {
                      "name": "Alcogel 70% 0.5 жёлтый"
                    },
                    {
                      "name": "Alcogel 70% 0.5 прозрачный"
                    },
                    {
                      "name": "Alcogel 70% 0.5 зелёный"
                    },
                    {
                      "name": "Мыло 0.5"
                    },
                    {
                      "name": "Мыло литр"
                    },
                    {
                      "name": "Мыло 0.2"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 2",
              "types": []
            },
            {
              "name": "Стеллаж 3",
              "types": [
                {
                  "name": "Soft салфетки",
                  "storageRow": "3",
                  "storagePosition": "Сторона 2 стеллаж 9 внизу",
                  "kinds": [
                    {
                      "name": "Губка Боб"
                    },
                    {
                      "name": "Синий хлопок"
                    },
                    {
                      "name": "Жёлтые"
                    },
                    {
                      "name": "Разноцветные голубые"
                    }
                  ]
                },
                {
                  "name": "Pastel салфетки",
                  "storageRow": "",
                  "storagePosition": "",
                  "kinds": [
                    {
                      "name": "Красные"
                    }
                  ]
                },
                {
                  "name": "Perfect салфетки с голубыми перьями",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 0 торец",
                  "kinds": [
                    {
                      "name": "Голубые"
                    }
                  ]
                },
                {
                  "name": "Perfect блок 7x100",
                  "storageRow": "6",
                  "storagePosition": "Стеллаж 10 сторона 2",
                  "kinds": [
                    {
                      "name": "Голубые с уголками"
                    }
                  ]
                },
                {
                  "name": "Soft губка Боб",
                  "storageRow": "2",
                  "storagePosition": "Сторона 2 стеллаж 2",
                  "kinds": []
                }
              ]
            },
            {
              "name": "Стеллаж 4",
              "types": [
                {
                  "name": "Kleenex салфетки синие пачка по 8",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Синие пачка по 8шт"
                    }
                  ]
                },
                {
                  "name": "Perfect салфетки синие пачка по 10",
                  "storageRow": "8",
                  "storagePosition": "Стеллаж 4",
                  "kinds": [
                    {
                      "name": "Синие пачка по 10шт"
                    }
                  ]
                },
                {
                  "name": "Kleenex салфетки бежевые",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Бежевые"
                    }
                  ]
                },
                {
                  "name": "Kleenex салфетки зелёные прямоугольные с цифрой 2",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Зелёные"
                    }
                  ]
                },
                {
                  "name": "Kleenex салфетки синие прямоугольные с цифрой 3",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Синие"
                    }
                  ]
                },
                {
                  "name": "Perfect салфетки с синими цветами",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 1",
                  "kinds": [
                    {
                      "name": "Голубые с синими цветами"
                    }
                  ]
                },
                {
                  "name": "Perfect салфетки синий кубик",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 1",
                  "kinds": [
                    {
                      "name": "Кубик картонный с уголками"
                    }
                  ]
                },
                {
                  "name": "Салфетки синие с якорем",
                  "storageRow": "9",
                  "storagePosition": "Сторона 2 стеллаж 5 внизу",
                  "kinds": [
                    {
                      "name": "5 пачек с тюльпанами"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 5",
              "types": [
                {
                  "name": "Kleenex салфетки синие картон по 2",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Синие"
                    }
                  ]
                },
                {
                  "name": "Kleenex салфетки зелёные кубик картон",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Зелёный кубик"
                    }
                  ]
                },
                {
                  "name": "Kleenex салфетки зелёные прямоугольные картон",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 7",
                  "kinds": [
                    {
                      "name": "Зелёные"
                    }
                  ]
                },
                {
                  "name": "Лили 5 салфетки тюльпаны",
                  "storageRow": "10",
                  "storagePosition": "",
                  "kinds": [
                    {
                      "name": "5 пачек с тюльпанами"
                    }
                  ]
                },
                {
                  "name": "Софт салфетки",
                  "storageRow": "3",
                  "storagePosition": "Сторона 2 стеллаж 9 внизу",
                  "kinds": [
                    {
                      "name": "Жёлтые и красные картон"
                    },
                    {
                      "name": "Красные мягкие цветы"
                    },
                    {
                      "name": "Soft Жёлтые мягкие листики"
                    },
                    {
                      "name": "Soft синие хлопок"
                    },
                    {
                      "name": "Soft синие разводы 500"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 6",
              "types": [
                {
                  "name": "Перчатки Easy touch винил серые",
                  "storageRow": "4",
                  "storagePosition": "0 торец",
                  "kinds": [
                    {
                      "name": "S"
                    },
                    {
                      "name": "M"
                    },
                    {
                      "name": "L"
                    },
                    {
                      "name": "XL"
                    }
                  ]
                },
                {
                  "name": "Целлофан перчатки синяя коробка",
                  "storageRow": "9",
                  "storagePosition": "Сторона 2 стеллаж 9 внизу",
                  "kinds": [
                    {
                      "name": "Синие"
                    }
                  ]
                },
                {
                  "name": "Перчатки Sano",
                  "storageRow": "3",
                  "storagePosition": "Стеллаж 10 внизу",
                  "kinds": [
                    {
                      "name": "Оранжевый S"
                    },
                    {
                      "name": "Синий M"
                    },
                    {
                      "name": "Зелёный L"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 7",
              "types": [
                {
                  "name": "Перчатки Easy Touch latex голубые",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 8",
                  "kinds": [
                    {
                      "name": "Красный Small"
                    },
                    {
                      "name": "Зелёный Medium"
                    },
                    {
                      "name": "Чёрный Large"
                    },
                    {
                      "name": "Белый X.Large"
                    }
                  ]
                },
                {
                  "name": "Перчатки Easy touch PVC серые",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 0 торец",
                  "kinds": [
                    {
                      "name": "Сиреневый small"
                    },
                    {
                      "name": "Зелёный medium"
                    },
                    {
                      "name": "Синий large"
                    },
                    {
                      "name": "Чёрный x.large"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 8",
              "types": [
                {
                  "name": "Перчатки бежевые рабочий в каске",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 0 торец",
                  "kinds": [
                    {
                      "name": "M"
                    },
                    {
                      "name": "L"
                    },
                    {
                      "name": "XL"
                    }
                  ]
                },
                {
                  "name": "Перчатки Safe touch latex",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 0 торец",
                  "kinds": [
                    {
                      "name": "Красные small"
                    },
                    {
                      "name": "Зелёные medium"
                    },
                    {
                      "name": "Синие large"
                    },
                    {
                      "name": "Фиолетовые x-large"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "Расходники для кухни",
          "shopPositions": [
            {
              "name": "Стеллаж 1",
              "types": [
                {
                  "name": "Красная фольга Diamond",
                  "storageRow": "6",
                  "storagePosition": "Сторона 2 стеллаж 6",
                  "kinds": [
                    {
                      "name": "Пачки по 2"
                    },
                    {
                      "name": "60"
                    },
                    {
                      "name": "22.8"
                    }
                  ]
                },
                {
                  "name": "Николь плёнки, фольга",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 8-11",
                  "kinds": [
                    {
                      "name": "Плёнка по 2 30см лимоны"
                    },
                    {
                      "name": "Бумага 60*30*15 кукуруза"
                    },
                    {
                      "name": "Бумага 25*37 печеньки"
                    },
                    {
                      "name": "Бумага 15 пачки по 2 7.5*30 кукуруза"
                    },
                    {
                      "name": "Бумага 20 пачки по 2 7.5*30 помидоры"
                    },
                    {
                      "name": "Бумага 16 пачки по 2 7.5*45 кукуруза"
                    },
                    {
                      "name": "Плёнка PVC 150 капуста"
                    },
                    {
                      "name": "Бумага 20 пачки по 2 7.5*45 помидоры"
                    },
                    {
                      "name": ""
                    }
                  ]
                },
                {
                  "name": "Сано пленки",
                  "storageRow": "1",
                  "storagePosition": "Сторона 2 стеллаж 4-8",
                  "kinds": [
                    {
                      "name": "Плёнка PVC 300 салат"
                    },
                    {
                      "name": "Плёнка PVC 150 салат"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 2",
              "types": [
                {
                  "name": "Сано пленки, фольга",
                  "storageRow": "1",
                  "storagePosition": "Стеллаж 4-8",
                  "kinds": [
                    {
                      "name": "Фольга пачки по 2 7.5*30*18"
                    },
                    {
                      "name": "Плёнка PVC 30 пачки по 2 салат"
                    },
                    {
                      "name": "Фольга пачки по 2 7.5*30*14"
                    },
                    {
                      "name": "Фольга 200SQFT 61 в круге"
                    },
                    {
                      "name": "Фольга пачки по 2 7.5*45*14"
                    },
                    {
                      "name": "Фольга 100*30*14"
                    },
                    {
                      "name": "Плёнка PVC 100 салат"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 3",
              "types": [
                {
                  "name": "Perfect плёнки, фольга",
                  "storageRow": "6",
                  "storagePosition": "Сторона 2 стеллаж 5-6",
                  "kinds": [
                    {
                      "name": "Фольга 7.5*30 пачки по 2 кукуруза"
                    },
                    {
                      "name": "Плёнка PVC 150 перец"
                    }
                  ]
                },
                {
                  "name": "Куки пленки, фольга",
                  "storageRow": "5",
                  "storagePosition": "Стеллаж 1",
                  "kinds": [
                    {
                      "name": "Плёнка PVC 30 пачки по 2 кочан салата"
                    },
                    {
                      "name": "Фольга звёздочки пачки по 2 30*7.5"
                    },
                    {
                      "name": "Плёнка PVC 120 пачки по 2 кочан салата"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 4",
              "types": [
                {
                  "name": "Куки для запекания",
                  "storageRow": "5",
                  "storagePosition": "Стеллаж 1",
                  "kinds": [
                    {
                      "name": "Бумага пицца"
                    },
                    {
                      "name": "Шапочки для салата L"
                    },
                    {
                      "name": "Шапочки для салата MIX"
                    },
                    {
                      "name": "Пакеты 10 L картошка"
                    },
                    {
                      "name": "Пакеты 5 XL курица"
                    },
                    {
                      "name": "Пакеты 10 XL курица"
                    },
                    {
                      "name": "Пакеты 20 L картошка"
                    },
                    {
                      "name": "Бумага 40 печеньки"
                    },
                    {
                      "name": "Бумага 40 брокколи"
                    },
                    {
                      "name": "Бумага 26 чизкейк вишня"
                    },
                    {
                      "name": "Бумага 28 пицца брокколи"
                    }
                  ]
                },
                {
                  "name": "Perfect бумага для запекания",
                  "storageRow": "9",
                  "storagePosition": "",
                  "kinds": [
                    {
                      "name": "50"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 5",
              "types": [
                {
                  "name": "Николь пакеты",
                  "storageRow": "10",
                  "storagePosition": "Сторона 2 стеллаж 8-11",
                  "kinds": [
                    {
                      "name": "Сендвич яблоко 200шт стеллаж 3"
                    },
                    {
                      "name": "Сендвич по 2 150шт"
                    },
                    {
                      "name": "Ягоды 12шт"
                    },
                    {
                      "name": "Сухофрукты 15шт"
                    },
                    {
                      "name": "Рулоны 3шт дыня"
                    },
                    {
                      "name": "Для мусора XXL чёрные голубое ведро"
                    },
                    {
                      "name": "Для мусора XXL оранжевые"
                    },
                    {
                      "name": "Для мусора L жёлтое ведро 55шт"
                    },
                    {
                      "name": "Для мусора L оранжевое ведро 40шт"
                    },
                    {
                      "name": "Для мусора XL зелёное ведро 45шт"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 6",
              "types": [
                {
                  "name": "Сано пакеты",
                  "storageRow": "1",
                  "storagePosition": "Сторона 2 стеллаж 4-8",
                  "kinds": [
                    {
                      "name": "250 XL голубой банан яблоко"
                    },
                    {
                      "name": "300 XL сиреневый сендвич"
                    },
                    {
                      "name": "150 L зелёный по 2 яблоки"
                    },
                    {
                      "name": "Зиплок 20 L голубой брокколи"
                    },
                    {
                      "name": "Зиплок 50 M сиреневый перец"
                    },
                    {
                      "name": "3 рулона 270 XXL голубой хлеб"
                    },
                    {
                      "name": "Мусор 25 XXL плоские зелёное ведро"
                    },
                    {
                      "name": "Мусор 30 XL плоские серое ведро"
                    },
                    {
                      "name": "Мусор 20 XXL зелёное ведро"
                    },
                    {
                      "name": "Мусор 30 XL чёрное ведро"
                    },
                    {
                      "name": "Мусор 20 XXL синее ведро"
                    },
                    {
                      "name": "Мусор 100 S жёлтое ведро"
                    },
                    {
                      "name": "Мусор 50 XXL зелёный красное ведро"
                    },
                    {
                      "name": "Мусор 60 L голубое ведро"
                    },
                    {
                      "name": "Мусор 40 L extra рулон серое ведро"
                    },
                    {
                      "name": "Мусор 100 L сиреневое ведро"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 7",
              "types": [
                {
                  "name": "Perfect пакеты",
                  "storageRow": "6",
                  "storagePosition": "Сторона 2 стеллаж 5-6",
                  "kinds": [
                    {
                      "name": "Сендвич по 2 150шт"
                    },
                    {
                      "name": ""
                    }
                  ]
                },
                {
                  "name": "Ziploc",
                  "storageRow": "4",
                  "storagePosition": "Стеллаж 7",
                  "kinds": [
                    {
                      "name": "Клубника 15"
                    },
                    {
                      "name": "Мясо 14"
                    },
                    {
                      "name": "Клубника 19"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Стеллаж 8",
              "types": [
                {
                  "name": "Perfect Мешки для мусора",
                  "storageRow": "6",
                  "storagePosition": "Сторона 2 стеллаж 4 внизу",
                  "kinds": [
                    {
                      "name": "XXL 25 плоские"
                    },
                    {
                      "name": "50"
                    },
                    {
                      "name": "10+10"
                    },
                    {
                      "name": "60"
                    },
                    {
                      "name": "100"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
    importData(data)
  }
  if (localStorage.getItem('data') === null) {
    initData()
  }
  const data = ref(null)
  const loadData = () => data.value = JSON.parse(localStorage.getItem('data'))
  loadData()
  const saveData = () => localStorage.setItem('data', JSON.stringify(data.value))
  const onReset = () => {
    if (!confirm('Are you sure you want to reset all?')) {
      return
    }
    initData()
    loadData()
    menu.value.open = false
  }
  
  const createDebouncer = (fn, delay, ...params) => {
    let intervalId;
    return () => {
      clearTimeout(intervalId)
      intervalId = setTimeout(fn, delay, ...params)
    }
  }
  
  watch(data, createDebouncer(saveData, 1000), {deep: true})
  
  const rowCount = 8
  const rowNumbers = Array.from({length: rowCount}, (_, i) => rowCount - i)
  const selectCell = event => {
    const myLocation = event.changedTouches[0]
    document.elementFromPoint(myLocation.clientX, myLocation.clientY).click()
  }
  
  const toggleStorageRow = rowName => data.value.filterStorageRows[rowName] ? delete data.value.filterStorageRows[rowName] : data.value.filterStorageRows[rowName] = true
  const isStorageRowVisible = rowName => data.value.filterStorageRows[rowName] === true || Object.keys(data.value.filterStorageRows).length === 0
  const clearKind = kind => {
    kind.missing = kind.missing.map(() => 0)
    kind.additional = 0
    kind.showWindow = false
  }
  const showAll = computed(() => Object.keys(data.value.filterStorageRows).length === 0 && !data.value.showMissingOnly)
  const isKindMissing = kind => kind.status === 0 && (kind.missing.some(e => e > 0) || kind.additional)
  const filterKinds = kinds => data.value.showMissingOnly ? kinds.filter(isKindMissing) : kinds
  const filterTypes = types => showAll.value ? types : types.filter(type => filterKinds(type.kinds).length > 0 && isStorageRowVisible(type.storageRow))
  const filterShopPositions = shopPositions => showAll.value ? shopPositions :  shopPositions.filter(shopPosition => filterTypes(shopPosition.types).length > 0)
  const filterDepartments = departments => showAll.value ? departments : departments.filter(department => filterShopPositions(department.shopPositions).length > 0)
  const storageRows = computed(() => {
    const rowsByName = new Map();
    data.value.departments.forEach(department => department.shopPositions.forEach(shopPosition => shopPosition.types.forEach(type => {
      if (type.storageRow === '') {
        return
      }
      if (!rowsByName.has(type.storageRow)) {
        rowsByName.set(type.storageRow, {
          name: type.storageRow,
          hasMissing: false,
          visible: isStorageRowVisible(type.storageRow),
        })
      }
      const row = rowsByName.get(type.storageRow)
      if (type.kinds.some(isKindMissing)) {
        row.hasMissing = true
      }
    })))
    const results = [...rowsByName.values()].sort((a, b) => a.name - b.name)
    return data.value.showMissingOnly ? results.filter(result => result.hasMissing) : results
  })
  watch(storageRows, () => {
    const filterStorageRows = data.value.filterStorageRows
    for (const rowName in filterStorageRows) {
      if (!storageRows.value.some(row => row.name === rowName)) {
        delete filterStorageRows[rowName]
      }
    }
  }, {deep: true})
</script>
<style>
body {
  font-family: Arial, sans-serif;
}
head, body {
    padding: 0;
    margin: 0;
}
div.menu {
    position: absolute;
    top: 0; right: 0;
    width: 5em;
    float: right;
    color: blue;
}
ul {
    padding-left: 1em;
    list-style-type: none;
}
.kind_no {
    text-decoration: line-through;
    color: gray;
}
table.storage_rows {
  border-collapse: collapse;
}
table.storage_rows td {
  border: 1px solid green;
  height: 2em;
  width: 2em;
  text-align: center;
}
table.storage_rows td.storage_row_hidden {
    text-decoration: line-through;
    color: gray;
    border: 1px dotted gray;
}
table.storage_rows td.storage_row_has_missing {
    background-color: lightblue;
}
div.show_missing_active {
  background-color: lightblue;
}
.select_missing {
  width: 100%;
  border-collapse: collapse;
}
.select_missing td {
  border: 1px dotted gray;
  height: 3em;
  width: 4em;
  text-align: center;
}
.item_has_missing {
    background-color: lightblue;
}
.cell_missing {
  background-color: lightblue;
}
.modal {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  height: 100vh;
  background-color: white;
  flex-direction: column;
  justify-content: center;
}

.modal div {
  justify-content: center;
  background-color: white;
  padding: 5px;
}
div.button {
     padding: 10px;
     margin: 5px;
     display: block;
     float: left;
     border-style: solid;
     border-width: 1px;
}
div.button.touch {
    background-color: green;
}
/*body.modal-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
}*/
</style>